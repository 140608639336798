import React from 'react';
import styled from 'styled-components';
import {
  bodyDefault,
  Color,
  h1Desktop,
  h1Mobile,
  h3Desktop,
  marginBottom,
  marginTop,
  maxWidth,
  typographyByBreakpoint,
} from '../../../styles';

const Wrapper = styled.section`
  ${maxWidth('32.7rem', '68.8rem', '97.8rem')};
  ${marginTop(80, 120, 210)};
  ${marginBottom(80, 100, 160)};
  margin-inline: auto;
`;

const Content = styled.div`
  h2 {
    ${typographyByBreakpoint(h1Mobile, h1Mobile, h1Desktop)};
  }

  > div {
    background-color: ${Color.WHITE};
    box-shadow: 33.7444px 33.7444px 60.7399px rgba(166, 180, 200, 0.1), 0 14.1616px 14.1616px rgba(0, 0, 0, 0.08);
    text-align: center;
    padding: 104px 0;
    margin-top: 38px;

    p {
      ${bodyDefault};
      margin: 8px;
    }
  }
`;

const VerifyOTP = ({ otpCode }: { otpCode: String})  => {
  return (
    <Wrapper id={'verifyOTP'}>
      <Content>
        <div>
          <p>To verify your email, please re-open your application and enter {otpCode} when prompted</p>
        </div>
      </Content>
    </Wrapper>
  );
};

export default VerifyOTP;
