import React, { useState } from 'react';
import WebsiteDefaultPage from '../../components/Page/Base';
import { Color } from '../../styles';
import { LIGHT_DARK } from '../../constants';
import VerifyOTP from '../../components/PageSpecific/apps/VerifyOTP';
import queryParams from '../../utils/query-params';


const VerifyEmail = () => {
  const [otp, setOtp] = useState(queryParams['otp'])

  return (
    <WebsiteDefaultPage bodyBackground={Color.OFF_WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
        <VerifyOTP otpCode={otp}/>
    </WebsiteDefaultPage>
  );
};

export default VerifyEmail;
